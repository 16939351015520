import { useCallback, useEffect } from 'react';
import L, { LocationEvent } from 'leaflet';
import { useMap } from 'react-leaflet';
import clsx from 'clsx';
import * as snackbarActions from 'src/store/actions/snackbar';
import * as tripActions from 'src/views/TripView/store/actions';
import 'leaflet.locatecontrol';
import { useDispatch } from 'react-redux';
import { colors, makeStyles, useTheme } from '@material-ui/core';
import Recenter from 'src/assets/icons/recenter.svg';
import Loader from 'src/assets/icons/loader.svg';
import { PointType } from 'src/views/TripView/types';
import { setUserLocation } from 'src/views/TripView/store/actions';
import { useTranslation } from 'react-i18next';
import { MIN_GPS_ACCURACY } from './const';

const useStyles = makeStyles(({ palette, shadows }) => ({
  '@global': {
    '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar.leaflet-control-locate': {
      border: 'none'
    },
    '.leaflet-touch .leaflet-bar.leaflet-control-locate a': {
      display: 'flex',
      backgroundColor: palette.common.white,
      borderRadius: '50%',
      width: 38,
      height: 38
    }
  },
  icon: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    boxShadow: shadows[4],
    borderRadius: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: 'none'
  },
  recenterIcon: {
    backgroundImage: `url(${Recenter})`
  },
  loadericon: {
    backgroundImage: `url(${Loader})`
  }
}));

const locationIconColor = colors.indigo.A700;

const LocateControl = () => {
  const {
    i18n: { language }
  } = useTranslation();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const map = useMap();
  const classes = useStyles();

  const showErrorMessage = useCallback(
    (message: string) =>
      dispatch(
        snackbarActions.enqueueSnackbar({
          key: 'locate_control_error',
          message,
          options: { variant: 'default' }
        })
      ),
    [dispatch]
  );

  const clearUserLocation = useCallback(() => {
    dispatch(setUserLocation(null));
  }, [dispatch]);

  useEffect(() => {
    if (map) {
      const control = L.control
        .locate({
          position: 'bottomright',
          keepCurrentZoomLevel: true,
          circleStyle: { fillColor: locationIconColor, fillOpacity: 0.2 },
          markerStyle: { fillColor: locationIconColor },
          // @ts-ignore
          initialZoomLevel: 13,
          icon: clsx(classes.icon, classes.recenterIcon),
          iconLoading: clsx(classes.icon, classes.loadericon),
          iconElementTag: 'div',
          clickBehavior: {
            inView: 'setView',
            outOfView: 'setView',
            inViewNotFollowing: 'setView'
          },
          locateOptions: {
            watch: false,
            enableHighAccuracy: true
          },
          onLocationError: () => {
            showErrorMessage(`locationError`);
            clearUserLocation();
          },
          createButtonCallback(container, options) {
            const link = L.DomUtil.create(
              'a',
              'leaflet-bar-part leaflet-bar-part-single',
              container
            );
            link.title = options.strings.title;
            const icon = L.DomUtil.create(
              options.iconElementTag as string,
              options.icon,
              link
            );
            link.onclick = () => control.stop();
            return { link, icon };
          }
        })
        .addTo(map);

      navigator?.permissions?.query({ name: 'geolocation' }).then(result => {
        // TODO: find solution for Safari
        if (result.state !== 'denied') {
          control.start();
        }
      });

      map.on('locationfound', (locationEvent: LocationEvent) => {
        if (locationEvent.accuracy < MIN_GPS_ACCURACY) {
          dispatch(
            tripActions.setUserLocationPoint.request({
              coordinates: locationEvent.bounds.getCenter(),
              pointType: PointType.START,
              language
            })
          );
        } else {
          control.stop();
          showErrorMessage(`locationBadAccuracy`);
          clearUserLocation();
        }
      });
    }
  }, [
    classes.icon,
    classes.loadericon,
    classes.recenterIcon,
    clearUserLocation,
    dispatch,
    language,
    map,
    palette.success.main,
    showErrorMessage
  ]);

  return null;
};

export default LocateControl;
