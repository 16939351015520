import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { LatLngLiteral } from 'leaflet';
import apiClient from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { PELIAS_SERVER, POI_SERVER } from 'src/config';
import { selectMapLimits } from 'src/store/selectors';
import { getCoordinatesParams, getRectBoundary } from 'src/utils/pelias';
import * as snackbarActions from 'src/store/actions/snackbar';
import { AxiosResponse } from 'axios';
import { Languages } from 'src/types/enums';
import { uniqBy } from 'lodash';
import * as selectors from './selectors';
import * as actions from './actions';
import {
  AutocompleteResponse,
  FavoriteRouteType,
  FavoritePlaceType,
  UserAddressType
} from '../types';
import { MAX_AUTOCOMPLETE } from '../const';
import {
  autocompletePointToSelectedPoint,
  getPointName,
  excludeWordsFromSearch,
  getLocationInformationByLocalization
} from '../utils';

export function* getLocationInfoByCoordinates({
  coordinates,
  language
}: {
  coordinates: LatLngLiteral;
  language: string;
}): SagaIterator {
  const { data }: { data: AutocompleteResponse } = yield call(
    apiClient.get,
    `${PELIAS_SERVER}/reverse?${getCoordinatesParams(coordinates)}`
  );

  const location = getLocationInformationByLocalization(
    coordinates,
    language as Languages,
    data.features ?? []
  );

  return {
    ...autocompletePointToSelectedPoint(location),
    lat: coordinates.lat,
    lng: coordinates.lng
  };
}

export function* getAutocompleteData({
  payload
}: ReturnType<typeof actions.getAutocompleteData.request>): SagaIterator {
  const mapLimits = yield select(selectMapLimits);
  const text = excludeWordsFromSearch(payload.text, ['вулиця', 'вул']);

  const {
    data
  }: AxiosResponse<AutocompleteResponse> = yield call(
    apiClient.get,
    `${PELIAS_SERVER}/autocomplete?size=${MAX_AUTOCOMPLETE}&${getRectBoundary(
      mapLimits
    )}`,
    { params: { ...payload, text } }
  );

  yield put(
    actions.getAutocompleteData.success(
      uniqBy(data.features, 'properties.name')
    )
  );
}

export function* setPointFromMap({
  payload: { coordinates, pointType, language }
}: ReturnType<typeof actions.setPointFromMap.request>): SagaIterator {
  const selectedPoint = yield call(getLocationInfoByCoordinates, {
    coordinates,
    language
  });

  yield put(actions.setPoint({ point: selectedPoint, pointType }));
}

export function* setUserLocationPoint({
  payload: { coordinates, pointType, language }
}: ReturnType<typeof actions.setUserLocationPoint.request>): SagaIterator {
  const selectedPoint = yield call(getLocationInfoByCoordinates, {
    coordinates,
    language
  });

  const isSelectOnMap = yield select(selectors.selectPointOnMap);

  const isStartAndDestinationSetted = yield select(
    selectors.selectIsSuggestedRoutesShown
  );

  if (isSelectOnMap) {
    yield put(
      actions.setPointFromMapOnMobile.request({
        coordinates,
        language
      })
    );
  }

  if (!isStartAndDestinationSetted && !isSelectOnMap) {
    yield put(actions.setPoint({ point: selectedPoint, pointType }));
  }

  yield put(actions.setUserLocation(coordinates));
}

export function* setPointFromMapOnMobile({
  payload: { coordinates, language }
}: ReturnType<typeof actions.setPointFromMapOnMobile.request>): SagaIterator {
  const selectedPoint = yield call(getLocationInfoByCoordinates, {
    coordinates,
    language
  });

  yield put(actions.setPointFromMapOnMobile.success(selectedPoint));
}

export function* getUserAddresses(): SagaIterator {
  const { data } = yield call(apiClient.get, `${POI_SERVER}/user-addresses`);

  yield put(actions.getUserAddresses.success(data));
}

export function* deleteUserAddress({
  payload
}: ReturnType<typeof actions.deleteUserAddress.request>): SagaIterator {
  yield call(apiClient.delete, `${POI_SERVER}/user-addresses/${payload}`);

  yield put(actions.deleteUserAddress.success(payload));
  yield put(
    snackbarActions.enqueueSnackbar({
      key: `delete-${payload}-address`,
      message:
        payload === UserAddressType.HOME
          ? 'homeAddressDeleted'
          : 'workAddressDeleted',
      options: {
        variant: 'warning'
      }
    })
  );
}

export function* addUserAddress({
  payload
}: ReturnType<typeof actions.addUserAddress.request>): SagaIterator {
  const addressType: UserAddressType = yield select(
    selectors.selectActiveUserAddress
  );
  yield call(apiClient.post, `${POI_SERVER}/user-addresses/`, {
    ...payload,
    addressType
  });
  yield put(actions.addUserAddress.success(payload));
  yield put(
    snackbarActions.enqueueSnackbar({
      key: `add-user-address-${addressType}`,
      message:
        addressType === UserAddressType.HOME
          ? 'homeAddressAdded'
          : 'workAddressAdded',
      options: {
        variant: 'success'
      }
    })
  );
}

export function* addUserAddressFromMap({
  payload: { coordinates, language }
}: ReturnType<typeof actions.addUserAddressFromMap.request>): SagaIterator {
  const selectedPoint = yield call(getLocationInfoByCoordinates, {
    coordinates,
    language
  });

  yield put(actions.addUserAddress.request(selectedPoint));
}

export function* getFavoritePlaces(): SagaIterator {
  const { data }: { data: FavoritePlaceType[] } = yield call(
    apiClient.get,
    `${POI_SERVER}/favorite-places`
  );

  yield put(actions.getFavoritePlaces.success(data));
}

export function* createFavoritePlace({
  payload
}: ReturnType<typeof actions.createFavoritePlace.request>): SagaIterator {
  const { data }: { data: number } = yield call(
    apiClient.post,
    `${POI_SERVER}/favorite-places`,
    payload
  );

  yield put(
    actions.createFavoritePlace.success({
      id: data,
      ...payload
    })
  );
}

export function* deleteFavoritePlace({
  payload
}: ReturnType<typeof actions.deleteFavoritePlace.request>): SagaIterator {
  yield call(apiClient.delete, `${POI_SERVER}/favorite-places/${payload}`);

  yield put(actions.deleteFavoritePlace.success(payload));
}

export function* handleFavoriteCreateFailture({
  payload
}: ReturnType<typeof actions.createFavoritePlace.failure>): SagaIterator {
  if (payload[0].code === 'favoritesMaximumLimitError') {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'favoritePlacesLimit',
        options: {
          variant: 'info'
        }
      })
    );
  }
}

export function* getFavoriteRoutes(): SagaIterator {
  const { data }: { data: FavoriteRouteType[] } = yield call(
    apiClient.get,
    `${POI_SERVER}/favorite-routes`
  );

  yield put(actions.getFavoriteRoutes.success(data));
}

export function* createFavoriteRoute(): SagaIterator {
  const from = yield select(selectors.selectStartPoint);
  const to = yield select(selectors.selectDestinationPoint);

  const { data }: { data: number } = yield call(
    apiClient.post,
    `${POI_SERVER}/favorite-routes`,
    {
      from: {
        ...from,
        address: from.address || getPointName(from)
      },
      to: {
        ...to,
        address: to.address || getPointName(to)
      }
    }
  );

  yield put(
    actions.createFavoriteRoute.success({
      id: data,
      from,
      to
    })
  );

  yield put(
    snackbarActions.enqueueSnackbar({
      key: 'add-route',
      message: 'routeAdded',
      options: {
        variant: 'success'
      }
    })
  );
}

export function* deleteFavoriteRoute({
  payload
}: ReturnType<typeof actions.deleteFavoriteRoute.request>): SagaIterator {
  yield call(apiClient.delete, `${POI_SERVER}/favorite-routes/${payload}`);

  yield put(actions.deleteFavoriteRoute.success(payload));
  yield put(
    snackbarActions.enqueueSnackbar({
      message: 'deleteRoute',
      options: {
        variant: 'warning'
      }
    })
  );
}

export default function* TripSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getAutocompleteData.request),
      safe(getAutocompleteData, actions.getAutocompleteData.failure)
    ),
    takeEvery(
      getType(actions.setPointFromMap.request),
      safe(setPointFromMap, actions.getAutocompleteData.failure)
    ),
    takeEvery(
      getType(actions.setPointFromMapOnMobile.request),
      safe(setPointFromMapOnMobile, actions.getAutocompleteData.failure)
    ),
    takeEvery(
      getType(actions.getUserAddresses.request),
      safe(getUserAddresses, actions.getUserAddresses.failure)
    ),
    takeEvery(
      getType(actions.deleteUserAddress.request),
      safe(deleteUserAddress, actions.deleteUserAddress.failure)
    ),
    takeEvery(
      getType(actions.addUserAddress.request),
      safe(addUserAddress, actions.addUserAddress.failure)
    ),
    takeEvery(
      getType(actions.addUserAddressFromMap.request),
      safe(addUserAddressFromMap, actions.getAutocompleteData.failure)
    ),
    takeEvery(
      getType(actions.getFavoritePlaces.request),
      safe(getFavoritePlaces, actions.getFavoritePlaces.failure)
    ),
    takeEvery(
      getType(actions.createFavoritePlace.request),
      safe(createFavoritePlace, actions.createFavoritePlace.failure)
    ),
    takeEvery(
      getType(actions.deleteFavoritePlace.request),
      safe(deleteFavoritePlace, actions.deleteFavoritePlace.failure)
    ),
    takeEvery(
      getType(actions.createFavoritePlace.failure),
      handleFavoriteCreateFailture
    ),
    takeEvery(
      getType(actions.getFavoriteRoutes.request),
      safe(getFavoriteRoutes, actions.getFavoriteRoutes.failure)
    ),
    takeEvery(
      getType(actions.createFavoriteRoute.request),
      safe(createFavoriteRoute, actions.createFavoriteRoute.failure)
    ),
    takeEvery(
      getType(actions.createFavoriteRoute.failure),
      handleFavoriteCreateFailture
    ),
    takeEvery(
      getType(actions.deleteFavoriteRoute.request),
      safe(deleteFavoriteRoute, actions.deleteFavoriteRoute.failure)
    ),
    takeEvery(
      getType(actions.setUserLocationPoint.request),
      safe(setUserLocationPoint, actions.setUserLocationPoint.failure)
    )
  ]);
}
